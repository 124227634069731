import FileImage from "../assets/file-image.svg";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  border-radius: 16px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 37px 32px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  font-weight: 600;
`;

const Title = styled.div`
  color: var(--Brown, #443727);
  flex-grow: 1;
  flex-basis: auto;
  font: 20px/150% Inter, sans-serif;
`;

const SeeAll = styled.div`
  color: var(--Blue, #0091c4);
  text-align: right;
  text-decoration-line: underline;
  font: 14px Inter, sans-serif;
`;

const ReportContainer = styled.div`
  border-radius: 10px;
  border: 1px solid var(--Gris, #888786);
  background-color: #fff;
  display: flex;
  margin-top: ${({ marginTop }) => marginTop || "0"};
  justify-content: space-between;
  gap: 16px;
  font-size: 14px;
  color: var(--Brown, #443727);
  padding: 16px 20px;
`;

const ReportImage = styled.img`
  aspect-ratio: 0.85;
  object-fit: auto;
  object-position: center;
  width: 23px;
`;

const ReportTitle = styled.div`
  font-family: Inter, sans-serif;
  flex-grow: 1;
  flex-basis: auto;
  margin: auto 0;
`;

const CategoryReports = ({ categories }) => {
  return (
    <Container>
      <HeaderContainer>
        <Title>Latest category reports</Title>
        <SeeAll>See all</SeeAll>
      </HeaderContainer>
      {categories.map((category, index) => (
        <ReportContainer key={index} marginTop={index === 0 ? "36px" : "12px"}>
          <ReportImage loading="lazy" src={FileImage} />
          <ReportTitle>{category.title}</ReportTitle>
        </ReportContainer>
      ))}
    </Container>
  );
};

export default CategoryReports;
