import BookmarkIcon from "../assets/bookmark-simple.svg";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  margin: 20px;
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #dadbdb;
  width: 100%;
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 5px;
`;

const NavItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
`;

const Title = styled.span`
  text-align: left;
  font-size: 14px;
  font-weight: 800;
  margin-bottom: 5px;
`;

const EditButton = styled.button`
  background-color: #38a9cf;
  border-radius: 20px;
  margin: 5px;
  padding: 5px 10px;
  color: white;
`;

const ActionButton = styled.button`
  background-color: #888786;
  border-radius: 20px;
  margin: 5px;
  padding: 5px 10px;
  color: white;
`;

const PointerSpan = styled.span`
  cursor: pointer;
  color: #38a9cf;
`;

const Column = styled.div`
  flex: 1;
  display: flex;
  margin-bottom: 10px;
  flex-direction: column;
`;

const NavText = styled.div`
  margin-left: 10px;
  margin-top: 0;
  font-size: 12px;
`;

const Icon = styled.img`
  width: 24px; /* Adjust size as needed */
  height: auto; /* Maintain aspect ratio */
`;

const StyledSelect = styled.select`
  width: 90%;
  padding: 8px;
  border-radius: 5px;
  border-color: #dadbdb;
`;

const H1 = styled.h1`
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const RedStar = styled.span`
  color: red;
`;

const SelectComponent = ({ title, options }) => {
  return (
    <Column>
      <TitleContainer>
        <Title>{title}</Title>
        <RedStar>*</RedStar>
      </TitleContainer>
      <StyledSelect>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </StyledSelect>
    </Column>
  );
};

const PersonalInsights = ({
  title = "Title",
  subtitle = "Subtitle",
  text = "Some text",
}) => {
  const subjectOptions = [
    { value: "subject1", label: "Subject 1" },
    { value: "subject2", label: "Subject 2" },
  ];
  const audienceOptions = [
    { value: "audience1", label: "Audience 1" },
    { value: "audience2", label: "Audience 2" },
  ];
  const productOptions = [
    { value: "product1", label: "Product 1" },
    { value: "product2", label: "Product 2" },
  ];

  return (
    <Container>
      <NavContainer>
        <NavItem>
          <PointerSpan>{"<"}</PointerSpan>
          <NavText>Back to Homepage</NavText>
        </NavItem>
        <NavItem>X</NavItem>
      </NavContainer>
      <FlexContainer>
        <H1>{title}</H1>
        <NavItem>
          <Icon src={BookmarkIcon} alt="Bookmark" />
        </NavItem>
      </FlexContainer>

      <div>
        <ActionButton>Modify</ActionButton>
        <ActionButton>Export as a PPT</ActionButton>
      </div>
      <FlexContainer>
        <SelectComponent title="Subject" options={subjectOptions} />
        <SelectComponent title="Target Audience" options={audienceOptions} />
        <SelectComponent title="Target Product" options={productOptions} />
      </FlexContainer>
      <EditButton>Generate</EditButton>
      <h4>{subtitle}</h4>
      <p>{text}</p>
    </Container>
  );
};

export default PersonalInsights;
