import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import Data from "./pages/Data";
import Insights from "./pages/Insights";
import Landing from "./pages/Landing";
import React, { useState } from "react";

function App() {
  const [isAuthed, setIsAuthed] = useState(false);
  if (!isAuthed) {
    return <form style={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    }} onSubmit={(e) => {
      e.preventDefault();
      const password = document.getElementById("password").value;
      if (password === "aie24") {
        setIsAuthed(true);
      }
    }}>
      <h1>Enter Password</h1>
      <input id="password" type="password" placeholder="password" />
      <button type="submit">Login</button>
    </form>
  }
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/data" element={<Data />} />
        <Route path="/insights" element={<Insights />} />
      </Routes>
    </Router>
  );
}

export default App;
