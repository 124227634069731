import React from "react";
import styled from "styled-components";
import Arrow from "../assets/arrow.svg";
import { Link } from "react-router-dom";

const Card = styled.div`
  background: #ffffff;
  border-radius: 16px;
  display: flex;
  justify-content: space-evenly;
  width: 305px;
  height: 154px;
  padding: 20px 10px 10px 10px;
  margin-right: 8px;
  position: relative;
`;

const Title = styled.p`
  font-size: 18px;
  margin: 10px 10px;
`;

const Image = styled.img`
  width: 76px;
`;

const ArrowIcon = styled.img`
  position: absolute;
  width: 50px;
  bottom: 20px;
  left: 20px;
`;


const NavCard = (props) => {
  return (
    <Link to={props.link} style={{ textDecoration: "none", color: "black" }}>
      <Card>
        <Title>{props.title}</Title>
        <Image src={props.img} alt={props.img} />
        <ArrowIcon src={Arrow} alt="arrow" />
      </Card>
    </Link>
  );
};

export default NavCard;