import BookmarkSimple from "../assets/bookmark-simple.svg";
import ChartLine from "../assets/chart-line.svg";
import FileText from "../assets/file-text.svg";
import GearSix from "../assets/gear-six.svg";
import House from "../assets/house.svg";
import { Link } from "react-router-dom";
import Question from "../assets/question.svg";
import React from "react";
import styled from "styled-components";

// Side Navbar styled components
const SideNavContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 60px;
  height: 100vh;
  background-color: #333333;
  padding: 10px;
  padding-top: 124px;
`;

const SideNavItem = styled(Link)`
  width: 30px;
  height: 30px;
  margin-bottom: 32px;
`;

const SideNavbar = () => {
  return (
    <SideNavContainer>
      <SideNavItem to="/" title="Home">
        <img src={House} alt="Home" />
      </SideNavItem>
      <SideNavItem to="/data" title="Data">
        <img src={ChartLine} alt="Charts" />
      </SideNavItem>
      <SideNavItem to="/insights" title="Insights">
        <img src={FileText} alt="Files" />
      </SideNavItem>
      <SideNavItem to="/bookmarks" title="Bookmarks">
        <img src={BookmarkSimple} alt="Bookmarks" />
      </SideNavItem>
      <SideNavItem to="/settings" title="Settings">
        <img src={GearSix} alt="Settings" />
      </SideNavItem>
      <SideNavItem to="/questions" title="Help">
        <img src={Question} alt="Help" />
      </SideNavItem>
    </SideNavContainer>
  );
};

export default SideNavbar;
