import React, { useState } from "react";

import CategoryReports from "./CategoryReports";
import MyKpis from "./MyKpis";
import PromotionalPlanning from "./PromotionalPlanning";
import SalesAdministration from "./SalesAdministration";
import SalesHotspots from "./SalesHotspots";
import TipsAndTricks from "./TipsAndTricks";
import styled from "styled-components";

// Full company data object
const companyData = {
  amazon: {
    kpis: [
      {
        title: "Market share",
        value: "18,2%",
        change: "(+300 bps YOY)",
        color: "#00a407",
      },
      { title: "Turnover", value: "$128", change: "(+1.1% YOY)" },
      { title: "Sales volume", value: "3,4m", change: "(+1.8% YOY)" },
      { title: "Gross Margin", value: "32%", change: "(-80 bps YOY)" },
      { title: "Net Margin", value: "18,3%", change: "(+30 bps YOY)" },
    ],
    categories: [{ title: "Cat food" }, { title: "Dog food" }],
    promotions: [
      { month: "March 2024", description: "Dry Dog 20% Off" },
      { month: "April 2024", description: "Coffeemate Chilled" },
      { month: "July 2024", description: "Spend $40 Save $10 - All" },
    ],
  },
  shop_retailer: {
    kpis: [
      {
        title: "Market share",
        value: "15%",
        change: "(+200 bps YOY)",
        color: "#ff5733",
      },
      { title: "Turnover", value: "$92", change: "(+0.8% YOY)" },
      { title: "Sales volume", value: "2.6m", change: "(+2.5% YOY)" },
      { title: "Gross Margin", value: "28%", change: "(-60 bps YOY)" },
      { title: "Net Margin", value: "14.5%", change: "(+20 bps YOY)" },
    ],
    categories: [{ title: "Cat food" }, { title: "Dog food" }],
    promotions: [
      { month: "March 2024", description: "Dry Dog 20% Off" },
      { month: "April 2024", description: "Coffeemate Chilled" },
      { month: "July 2024", description: "Spend $40 Save $10 - All" },
    ],
  }
};

const ContentWrapper = styled.div`
  flex: 2;
  max-height: calc(100vh - 60px);
  overflow: scroll;
`;

const ContentDiv = styled.div`
  flex: 2;
  background-color: #f6f5f4;
  border: 1px solid #cccccc;
  padding: 10px;
  overflow: scroll;
  max-height: calc(100vh - 60px);
`;

const Header = styled.h3`
  font-size: 10px;
  margin-bottom: 5px;
  color: #666666;
`;

const Dropdown = styled.select`
  width: 100%;
  font-size: 26px;
  padding: 5px;
  margin-bottom: 10px;
`;

const HalfWidthContainer = styled.div`
  display: flex;
`;

const HalfWidthBox = styled.div`
  flex-grow: 1;
  width: 50%;
  margin: 0 5px;
  border-radius: 16px;
  background-color: #fff;
  padding: 45px 32px;
  margin-top: 15px;

  @media (max-width: 991px) {
    padding: 0 20px;
    margin-top: 40px;
  }
`;

export default function ContentContainer() {
  const [selectedClient, setSelectedClient] = useState("amazon");

  const handleChange = (event) => {
    setSelectedClient(event.target.value);
  };

  return (
    <ContentWrapper>
      <ContentDiv>
        <Header>Client</Header>
        <Dropdown value={selectedClient} onChange={handleChange}>
          <option value="amazon">Amazon</option>
          <option value="shop_retailer">Walmart</option>
        </Dropdown>
        <MyKpis kpis={companyData[selectedClient].kpis} />
        <HalfWidthContainer>
          <HalfWidthBox>
            <SalesAdministration />
          </HalfWidthBox>
          <HalfWidthBox>
            <SalesHotspots />
          </HalfWidthBox>
        </HalfWidthContainer>
        <HalfWidthContainer>
          <HalfWidthBox>
            <CategoryReports
              categories={companyData[selectedClient].categories}
            />
          </HalfWidthBox>
          <HalfWidthBox>
            <PromotionalPlanning
              promotions={companyData[selectedClient].promotions}
            />
          </HalfWidthBox>
        </HalfWidthContainer>
        <TipsAndTricks />
      </ContentDiv>
    </ContentWrapper>
  );
}
