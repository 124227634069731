import ContentContainer from "../components/ContentContainer";
import DialogflowMessenger from "../components/DialogflowMessenger";
import NavBar from "../components/NavBar";
import React from "react";
import SideNavbar from "../components/SideNavbar";
import styled from "styled-components";

const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #f7f7f8;
`;

const Data = () => {
  return (
    <div>
      <NavBar />
      <FlexDiv>
        <SideNavbar />
        <ContentContainer />
        <DialogflowMessenger />
      </FlexDiv>
    </div>
  );
};

export default Data;
