import * as React from "react";

import styled from "styled-components";

function SalesAdministration(props) {
  return (
    <>
      <Header>
        <Title>Sales Administration</Title>
        <SeeAll>See all</SeeAll>
      </Header>
      <Options>
        <Option>
          <OptionImage
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/3157afc63019d6705d8a82e5e50e77cebf2b5c592d460cc0ef762cb1f0e72268?apiKey=fc8876ccb386458f965cbb9ed6a26304&"
          />
          <OptionText>Create promotion</OptionText>
        </Option>
        <Option>
          <OptionImage
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/7cb95ff06fededcdbee3ef3fe83e5afebef85d3064647e3e4797ce85ecc5a2c9?apiKey=fc8876ccb386458f965cbb9ed6a26304&"
          />
          <OptionText>Create forecast buy</OptionText>
        </Option>
      </Options>
    </>
  );
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
`;

const Title = styled.div`
  color: var(--Brown, #443727);
  flex-grow: 1;
  font: 20px/150% Inter, sans-serif;
`;

const SeeAll = styled.div`
  color: var(--Blue, #0091c4);
  text-align: right;
  text-decoration-line: underline;
  font: 14px Inter, sans-serif;
`;

const Options = styled.div`
  display: flex;
  margin-top: 37px;
  justify-content: space-between;
  gap: 18px;
  font-size: 14px;
  color: var(--Brown, #443727);
  font-weight: 500;
  text-align: center;
`;

const Option = styled.div`
  border-radius: 10px;
  border: 1px solid var(--Gris, #888786);
  background-color: #fff;
  flex-grow: 1;
  flex-basis: 0%;
  display: flex;
  flex-direction: column;
  padding: 23px 49px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const OptionImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
`;

const OptionText = styled.div`
  font-family: Inter, sans-serif;
  margin-top: 11px;
`;

export default SalesAdministration;
