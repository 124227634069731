import React from "react";
import styled from "styled-components";

const Container = styled.div`
  border-radius: 16px;
  background-color: #fff;
  padding: 34px 0px;
  width: 100%;
  max-width: 800px;

  @media (max-width: 991px) {
    padding-left: 20px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.div`
  color: var(--Brown, #443727);
  font-weight: 700;
  font-size: 20px;
`;

const SeeAll = styled.div`
  color: var(--Blue, #0091c4);
  text-align: right;
  text-decoration-line: underline;
  font-weight: 600;
  font-size: 14px;
`;

const EntryContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  line-height: 150%;
  margin-bottom: 20px;
`;

const Month = styled.div`
  color: var(--Gris, #888786);
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  flex-shrink: 0;
`;

const Promotion = styled.div`
  color: var(--Brown, #443727);
  text-align: right;
  font-weight: 400;
  font-size: 16px;
  flex-grow: 1;
`;

const Divider = styled.div`
  background-color: #888786;
  height: 1px;
  margin-top: 20px;
`;

const PromotionalPlanning = ({ promotions }) => {
  return (
    <Container>
      <HeaderContainer>
        <Title>Promotional planning</Title>
        <SeeAll>See all</SeeAll>
      </HeaderContainer>
      {promotions.map((promo, index) => (
        <React.Fragment key={index}>
          <EntryContainer>
            <Month>{promo.month}</Month>
            <Promotion>{promo.description}</Promotion>
          </EntryContainer>
          <Divider />
        </React.Fragment>
      ))}
    </Container>
  );
};

export default PromotionalPlanning;
