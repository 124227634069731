import React from "react";
import styled from "styled-components";

// Top Navbar styled components
const TopNavbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 10px 20px;
`;

const UserName = styled.div`
  font-size: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const DropdownIcon = styled.img`
  margin-right: 5px;
`;

const DropdownMenu = styled.select`
  border: none;
`;

const DropdownOption = styled.option`
  // May need some styling at some point
`;

export default function NavBar() {
  return (
    <TopNavbar>
      <UserName>
        <DropdownIcon
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/4ad4ad9b79cc4d423fce5c905a704fc01802e5073e171e473758df732274c9ae?apiKey=fc8876ccb386458f965cbb9ed6a26304&"
          alt="User Icon"
        />
        <DropdownMenu>
          <DropdownOption value="option1">Dan Cotton</DropdownOption>
          <DropdownOption value="option2">Dan Cotton 2</DropdownOption>
          <DropdownOption value="option3">Dan Cotton 3</DropdownOption>
        </DropdownMenu>
      </UserName>
    </TopNavbar>
  );
}
