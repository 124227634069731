import React from "react";
import styled from "styled-components";
import BSP from "../assets/brand-strategic-plans.svg";
import ICP from "../assets/integrated-commercial-planning.svg";
import PH from "../assets/performance-hub.svg";
import SRM from "../assets/strategic-revenue-management.svg";
import NavCard from "./NavCard";

const MainDiv = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  max-height: calc(100vh - 60px);
  overflow: scroll;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    box-sizing: border-box;
    width: 100%;
    min-height: 100%;
    gap: 2rem;
  }

  @media screen and (max-width: 975px) {
    & > div {
      justify-content: flex-start;
    }
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Titles = styled.div`
  display: flex;
  flex-direction: column;
`;

const Cards = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  margin: 10rem 5rem;
  margin-top: 2rem;
`;

const Title = styled.h1`
  font-size: 64px;
  margin: 0 auto;
  color: #443727;
`;

const Subtitle = styled.h3`
  font-size: 14px;
  margin: 0 auto;
  color: #443727;
`;

const cards = [
  {
    title: "Performance Hub",
    img: PH,
    link: "/data"
  },
  {
    title: "Strategic Revenue Management",
    img: SRM,
    link: "/not-found"
  },
  {
    title: "Integrated Commercial Planning",
    img: ICP,
    link: "/not-found"
  },
  {
    title: "Brand Strategic Plans",
    img: BSP,
    link: "/not-found"
  },
];

const LandingContent = () => {
  return (
    <MainDiv>
      <div>
        <Header>
          <Titles>
            <Title>Joyce</Title>
            <Subtitle>Your Smart AI Sales Assistant</Subtitle>
          </Titles>
        </Header>
        {/* <Search>
          <SearchInput type="text" placeholder="Ask Assistant" />
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_622_2056)">
              <path d="M5.02941 8.80242C5.07372 8.92462 5.07372 9.0585 5.02941 9.1807L2.84972 15.5587C2.81075 15.6664 2.80557 15.7834 2.83487 15.8941C2.86417 16.0048 2.92656 16.1039 3.01369 16.1782C3.10082 16.2525 3.20855 16.2984 3.32249 16.3099C3.43642 16.3213 3.55113 16.2977 3.65128 16.2422L15.4638 9.48586C15.5517 9.43721 15.625 9.36591 15.676 9.27935C15.727 9.1928 15.754 9.09415 15.754 8.99367C15.754 8.89319 15.727 8.79455 15.676 8.70799C15.625 8.62143 15.5517 8.55013 15.4638 8.50148L3.65128 1.76203C3.55137 1.70615 3.43679 1.68209 3.32285 1.69305C3.2089 1.70401 3.10101 1.74948 3.01359 1.82337C2.92616 1.89727 2.86336 1.99609 2.83358 2.10662C2.8038 2.21715 2.80845 2.33413 2.84691 2.44195L5.02941 8.80242Z" stroke="#0091C4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M9.5625 9H5.0625" stroke="#0091C4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
              <clipPath id="clip0_622_2056">
                <rect width="18" height="18" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Search> */}
        <Cards>
          {
            cards.map((card, index) => (
              <NavCard key={index} title={card.title} img={card.img} link="/data" />
            ))
          }
        </Cards>
      </div>
    </MainDiv>
  );
};

export default LandingContent;