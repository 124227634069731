import NavBar from "../components/NavBar";
import React from "react";
import SideNavbar from "../components/SideNavbar";
import styled from "styled-components";
import LandingContent from "../components/LandingContent";

const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #f7f7f8;
`;

const Landing = () => {
  return (
    <div>
      <NavBar />
      <FlexDiv>
        <SideNavbar />
        <LandingContent />
      </FlexDiv>
    </div>
  );
};

export default Landing;
