import React from "react";
import styled from "styled-components";

const Container = styled.div`
  border-radius: 16px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 45px 32px;
  margin-top: 46px;

  @media (max-width: 991px) {
    padding: 0 20px;
    margin-top: 40px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const Title = styled.div`
  color: var(--Brown, #443727);
  font: 20px/150% Inter, sans-serif;
`;

const SeeAll = styled.div`
  color: var(--Blue, #0091c4);
  text-align: right;
  text-decoration-line: underline;
  font: 14px Inter, sans-serif;
`;

const KpiContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  white-space: nowrap;
  margin: 36px 0 4px;

  @media (max-width: 991px) {
    flex-wrap: wrap;
    white-space: initial;
  }
`;

const Kpi = styled.div`
  display: flex;
  flex-direction: column;
`;

const KpiTitle = styled.div`
  color: var(--Brown, #443727);
  font: 600 14px Inter, sans-serif;
`;

const KpiValue = styled.div`
  color: ${({ color }) => color || "#00a407"};
  margin-top: 7px;
  font: 300 40px Inter, sans-serif;
`;

const KpiChange = styled.div`
  color: #888786;
  font: 500 12px Inter, sans-serif;
`;

function MyKpis(props) {
  const { kpis } = props;
  return (
    <Container>
      <Header>
        <Title>My KPIs</Title>
        <SeeAll>See all</SeeAll>
      </Header>
      <KpiContainer>
        {kpis.map((kpi, index) => (
          <Kpi key={index}>
            <KpiTitle>{kpi.title}</KpiTitle>
            <KpiValue color={kpi.color}>{kpi.value}</KpiValue>
            <KpiChange>{kpi.change}</KpiChange>
          </Kpi>
        ))}
      </KpiContainer>
    </Container>
  );
}

export default MyKpis;
