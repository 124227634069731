import React from "react";
import styled from "styled-components";

const Container = styled.div`
  height: calc(100vh - 60px);
  max-height: calc(100vh - 60px);
  position: relative;
  width: 37.5vw;
  
  & df-messenger {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  & df-messenger-chat {
    --df-messenger-titlebar-padding: 15px 15px;
    --df-messenger-titlebar-icon-padding: 12px;
    --df-messenger-titlebar-title-font-size: 28px;
    --df-messenger-titlebar-icon-height: 48px;
    --df-messenger-titlebar-icon-width: 43.34px;
    --df-messenger-titlebar-border-bottom: 0px;
    --df-messenger-chat-background: #ffffff;
    --df-messenger-chat-bubble-border-radius: 10px;
    --df-messenger-message-user-background: #027782;
    --df-messenger-message-user-font-color: #ffffff;
    --df-messenger-message-bot-background: #F7F7F8;
    --df-messenger-message-bot-font-color: #443727;
    --df-messenger-input-background: #ffffff;
    --df-messenger-input-box-border-radius: 30px;
    --df-messenger-send-icon-color: #ffffff;
    --df-messenger-message-bot-writing-image-height: 20px;
    --df-messenger-message-bot-writing-image-width: 57px;
  }
`;

const DialogflowChatbot = () => {
  return (
    <Container>
      <df-messenger
        project-id="nestle-genai-hack-negotiation"
        agent-id="46d92486-597e-4d1a-bf1b-e0b2e125d0e0"
        language-code="en"
        max-query-length="-1"
      >
        <df-messenger-chat
          chat-title="Ask Joyce"
          bot-writing-image="/bot-typing.gif"
        ></df-messenger-chat>
      </df-messenger>
    </Container>
  );
};

export default DialogflowChatbot;
