import React from "react";
import styled from "styled-components";

const sections = [
  {
    title: "Shopper Engagement",
    description: "Helping you identify & reach your key audiences",
    imageUrl:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/bdc26092962197092849693de15f4002f9ff1a4ed9133fc85792c455cf85e672?apiKey=fc8876ccb386458f965cbb9ed6a26304",
  },
  {
    title: "Digital Shelf",
    description: "Winning the Digital Shelf with best-in-class eContent",
    imageUrl:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/dcf7d87838fad3f81916297c34bc37ee7457586daeb6e653a74ebf9cf8353f48?apiKey=fc8876ccb386458f965cbb9ed6a26304",
  },
  {
    title: "Customer & Partner Management",
    description: "How to create winning relationships with our customers",
    imageUrl:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/f94f045b6e466d2c408a8fdd6ef3a1a5d4f7bb3489204cf7e972ef89b2cb6091?apiKey=fc8876ccb386458f965cbb9ed6a26304",
  },
  {
    title: "Flexible operations",
    description: "Driving commercial outcomes through operational capabilities",
    imageUrl:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/a8216946c1287171905cf77532d14c214f8baf100b7c19e0395f6b2ebb1b05fc?apiKey=fc8876ccb386458f965cbb9ed6a26304",
  },
  {
    title: "Marketing Automation",
    description: "Unlocking customer insights and driving ROI",
    imageUrl:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/0f85d2a5370d286d9880894d514f2832c95bd4bc29ef4a0e2d7c1ceb9a235e07?apiKey=fc8876ccb386458f965cbb9ed6a26304",
  },
  {
    title: "Sales Analytics",
    description: "Gaining insights from customer data to drive business decisions",
    imageUrl:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/37ef6daaf4391fab3a2227099e435e8c6468ffab90ffe747d3f36b5f0cb6deeb?apiKey=fc8876ccb386458f965cbb9ed6a26304",
  },
];

function TipsAndTricks() {
  return (
    <Container>
      <Header>
        <div style={{
          display: 'flex',
          alignItems: 'center'
        }}>
          <svg width="50" height="50" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M57.8057 54.5497C59.4975 55.0354 61.4362 55.4239 63.6585 55.7652C61.4363 56.1067 59.4976 56.4954 57.8058 56.9811C54.8847 57.8197 52.632 58.9658 50.8987 60.7183C49.1661 62.47 48.034 64.7454 47.2055 67.6961C46.7293 69.3923 46.3474 71.3343 46.0119 73.5591C45.6764 71.3343 45.2945 69.3921 44.8183 67.6959C43.9898 64.7452 42.8575 62.4698 41.1248 60.7181C39.3914 58.9656 37.1384 57.8196 34.2171 56.981C32.5247 56.4951 30.5853 56.1064 28.362 55.7648C30.5852 55.4233 32.5246 55.0346 34.217 54.5488C37.1383 53.7102 39.3912 52.5642 41.1246 50.8117C42.8573 49.0599 43.9896 46.7845 44.8181 43.8338C45.2944 42.1376 45.6763 40.1954 46.0118 37.9705C46.3473 40.1954 46.7291 42.1376 47.2053 43.8338C48.0337 46.7847 49.1659 49.0603 50.8984 50.8122C52.6317 52.5649 54.8845 53.7111 57.8057 54.5497ZM25.0068 35.2441C23.7954 34.0217 22.162 33.3042 20.0086 32.805C22.1621 32.306 23.7954 31.5882 25.0066 30.3651C26.2106 29.1492 26.9197 27.512 27.4132 25.3533C27.907 27.5116 28.6163 29.1485 29.8203 30.3643C31.0314 31.5873 32.6644 32.3051 34.8171 32.8043C32.6641 33.3032 31.0311 34.0209 29.8201 35.2437C28.6164 36.459 27.9072 38.0951 27.4137 40.2523C26.9202 38.0951 26.2109 36.4592 25.0068 35.2441ZM60.3828 27.1379C58.7546 28.7828 57.8697 31.0847 57.2571 34.2679C56.6449 31.0854 55.7601 28.7838 54.1316 27.139C52.4946 25.4856 50.2001 24.5906 47.0281 23.9708C50.2003 23.3515 52.4949 22.4567 54.132 20.8032C55.7607 19.1583 56.6454 16.8563 57.2575 13.6731C57.8702 16.8562 58.7551 19.1581 60.3833 20.8029C62.0198 22.4562 64.3131 23.351 67.4831 23.9703C64.3128 24.5897 62.0194 25.4846 60.3828 27.1379Z" stroke="#0091C4" stroke-width="2"/>
          </svg>
          <HeaderText>Joyce Recommends</HeaderText>
        </div>
        <SeeAllLink href="#">See all</SeeAllLink>
      </Header>
      <Sections>
        {sections.map((section, index) => (
          <Section key={index}>
            <ImageContainer>
              <SectionImage srcSet={section.imageUrl} />
            </ImageContainer>
            <SectionContent>
              <SectionTitle>{section.title}</SectionTitle>
              <SectionDescription>{section.description}</SectionDescription>
            </SectionContent>
          </Section>
        ))}
      </Sections>
    </Container>
  );
}

const ImageContainer = styled.div`
  padding: 0px;
`;

const Container = styled.div`
  border-radius: 16px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 43px 32px;
  margin-top: 15px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const HeaderText = styled.div`
  color: var(--Brown, #443727);
  font: 20px/150% Inter, sans-serif;
`;

const SeeAllLink = styled.a`
  color: var(--Blue, #0091c4);
  text-decoration-line: underline;
  font: 14px Inter, sans-serif;
`;

const Sections = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const Section = styled.div`
  border-radius: 10px;
  border: 1px solid var(--Gris, #888786);
  display: flex;
  flex-direction: column;
  color: var(--Brown, #443727);
  width: 25%;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const SectionImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

const SectionContent = styled.div`
  margin-top: 20px;
  padding: 0px 0px 10px 10px;
`;

const SectionTitle = styled.div`
  font: 700 16px Inter, sans-serif;
`;

const SectionDescription = styled.div`
  margin-top: 8px;
  font: 400 14px/20px Inter, sans-serif;
`;

export default TipsAndTricks;
